import Collider from "../@core/Collider"
import Sprite from "../@core/Sprite"
import GameObject from "../@core/GameObject"
import useGameObject from "../@core/useGameObject"
import useGameObjectEvent from "../@core/useGameObjectEvent"

function FogScript() {
  const { getRef } = useGameObject()

  useGameObjectEvent("trigger", (other) => {
    if (other.name === "player") {
      getRef().setDisabled(true)
    }
  })

  return null
}

function Instance() {
  return (
    <mesh>
      <boxGeometry args={[1, 1, 0.1]} />
      <meshBasicMaterial color="#120925" />
    </mesh>
  )
}

function Fog(props) {
  return (
    <GameObject {...props} z={0.5}>
      <Sprite meshInstance={<Instance />} />
      <Collider isTrigger />
      <FogScript />
    </GameObject>
  )
}

export default Fog

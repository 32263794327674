import { createPortal } from "react-dom"
import styled from "styled-components"

function ModalLayer({ children, show, zIndex = 1 }) {
  if (!show) {
    return null
  }

  return (
    <>
      {createPortal(
        <Wrap z={zIndex}>{children}</Wrap>,
        document.getElementById("modal-layer"),
      )}
    </>
  )
}

const Wrap = styled.div`
  position: absolute;
  z-index: ${({ z }) => z};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

export default ModalLayer

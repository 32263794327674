import { useRef, useEffect, useState } from "react"
import { useFrame, useThree } from "react-three-fiber"
import { Html } from "@react-three/drei"
import styled from "styled-components"

import Collider from "../@core/Collider"
import GameObject from "../@core/GameObject"
import Interactable from "../@core/Interactable"
import Moveable from "../@core/Moveable"
import useCollisionTest from "../@core/useCollisionTest"
import useGameLoop from "../@core/useGameLoop"
import useGameObject from "../@core/useGameObject"
import useKeyPress from "../@core/useKeyPress"
import tileUtils from "../@core/utils/tileUtils"
import useGameObjectEvent from "../@core/useGameObjectEvent"
import useStore from "../store"
import { CENTER } from "../App"
import ModalLayer from "../ui/ModalLayer"

const TOUCH_DEVICE = "ontouchstart" in document.documentElement

function Caret(props) {
  return (
    <svg
      {...props}
      width="40px"
      height="40px"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.18179 8.81819C4.00605 8.64245 4.00605 8.35753 4.18179 8.18179L7.18179 5.18179C7.26618 5.0974 7.38064 5.04999 7.49999 5.04999C7.61933 5.04999 7.73379 5.0974 7.81819 5.18179L10.8182 8.18179C10.9939 8.35753 10.9939 8.64245 10.8182 8.81819C10.6424 8.99392 10.3575 8.99392 10.1818 8.81819L7.49999 6.13638L4.81819 8.81819C4.64245 8.99392 4.35753 8.99392 4.18179 8.81819Z"
        fill="#000000"
      />
    </svg>
  )
}

const DownCaret = styled(Caret)`
  transform: rotate(180deg);
`
const LeftCaret = styled(Caret)`
  transform: rotate(270deg);
`
const RightCaret = styled(Caret)`
  transform: rotate(90deg);
`

const Btn = styled.button`
  position: absolute;
  padding: 20px;
  border: none;
  opacity: 0.3;
  background: #8080ff;

  &:active {
    opacity: 0.8;
  }
`

const UpBtn = styled(Btn)`
  top: 4px;
  left: 2px;
  right: 2px;
`

const DownBtn = styled(Btn)`
  bottom: 4px;
  left: 2px;
  right: 2px;
`

const LeftBtn = styled(Btn)`
  top: 2px;
  bottom: 2px;
  left: 4px;
`

const RightBtn = styled(Btn)`
  top: 2px;
  bottom: 2px;
  right: 4px;
`

function TouchControlsScript() {
  const { getComponent, transform } = useGameObject()
  const testCollision = useCollisionTest()
  const [path, setPath] = useState([])

  // mouse controls
  const left = useRef(false)
  const right = useRef(false)
  const up = useRef(false)
  const down = useRef(false)

  // update the path
  useGameLoop(() => {
    const direction = {
      x: -Number(left.current) + Number(right.current),
      y: Number(up.current) - Number(down.current),
    }
    const nextPosition = tileUtils(transform).add(direction)
    // is same position?
    if (tileUtils(nextPosition).equals(transform)) return

    // is already moving?
    if (!getComponent("Moveable").canMove()) return

    // will cut corner?
    const horizontal = { ...transform, x: nextPosition.x }
    const vertical = { ...transform, y: nextPosition.y }
    const canCross =
      direction.x !== 0 && direction.y !== 0
        ? // test diagonal movement
          testCollision(horizontal) && testCollision(vertical)
        : true

    if (canCross) {
      setPath([nextPosition])
    }
  })

  // walk the path
  useEffect(() => {
    if (!path.length) return

    const [nextPosition] = path

    const run = async () => {
      const anyAction =
        (await getComponent("Moveable")?.move(nextPosition)) ||
        (path.length === 1 && // try interaction on last step of path
          (await getComponent)("Interactable").interact(nextPosition))

      if (anyAction) {
        // proceed with next step in path
        setPath((current) => current.slice(1))
      }
    }
    run()
  }, [path, getComponent])

  return (
    <Html>
      <ModalLayer show={true}>
        <UpBtn
          onTouchStart={() => (up.current = true)}
          onTouchEnd={() => (up.current = false)}
        >
          <Caret />
        </UpBtn>
        <DownBtn
          onTouchStart={() => (down.current = true)}
          onTouchEnd={() => (down.current = false)}
        >
          <DownCaret />
        </DownBtn>
        <LeftBtn
          onTouchStart={() => (left.current = true)}
          onTouchEnd={() => (left.current = false)}
        >
          <LeftCaret />
        </LeftBtn>
        <RightBtn
          onTouchStart={() => (right.current = true)}
          onTouchEnd={() => (right.current = false)}
        >
          <RightCaret />
        </RightBtn>
      </ModalLayer>
    </Html>
  )
}

function PlayerScript() {
  const { getComponent, transform } = useGameObject()
  const testCollision = useCollisionTest()
  const [path, setPath] = useState([])

  // key controls
  const leftKey = useKeyPress(["ArrowLeft", "a"])
  const rightKey = useKeyPress(["ArrowRight", "d"])
  const upKey = useKeyPress(["ArrowUp", "w"])
  const downKey = useKeyPress(["ArrowDown", "s"])

  // update the path
  useGameLoop(() => {
    const direction = {
      x: -Number(leftKey) + Number(rightKey),
      y: Number(upKey) - Number(downKey),
    }
    const nextPosition = tileUtils(transform).add(direction)
    // is same position?
    if (tileUtils(nextPosition).equals(transform)) return

    // is already moving?
    if (!getComponent("Moveable").canMove()) return

    // will cut corner?
    const horizontal = { ...transform, x: nextPosition.x }
    const vertical = { ...transform, y: nextPosition.y }
    const canCross =
      direction.x !== 0 && direction.y !== 0
        ? // test diagonal movement
          testCollision(horizontal) && testCollision(vertical)
        : true

    if (canCross) {
      setPath([nextPosition])
    }
  })

  // walk the path
  useEffect(() => {
    if (!path.length) return

    const [nextPosition] = path

    const run = async () => {
      const anyAction =
        (await getComponent("Moveable")?.move(nextPosition)) ||
        (path.length === 1 && // try interaction on last step of path
          (await getComponent)("Interactable").interact(nextPosition))

      if (anyAction) {
        // proceed with next step in path
        setPath((current) => current.slice(1))
      }
    }
    run()
  }, [path, getComponent])

  return null
}

function CameraFollowScript() {
  const { camera } = useThree()

  // following camera
  useFrame(() => {
    camera.position.set(CENTER, CENTER, 20)
    camera.lookAt(CENTER, CENTER, 20)
  })

  return null
}

function AddStepScript() {
  useGameObjectEvent("will-move", ({ x, y }) => {
    useStore.setState((state) => ({
      steps: state.steps + 1,
      exitPath: [...state.exitPath, { x, y }],
    }))
  })

  return null
}

export default function Player(props) {
  return (
    <GameObject name="player" {...props}>
      <Moveable />
      <Interactable />
      <Collider />
      <mesh>
        <sphereGeometry args={[0.2]} />
        <meshNormalMaterial color="black" />
      </mesh>
      <PlayerScript />
      <CameraFollowScript />
      <AddStepScript />
      {TOUCH_DEVICE && <TouchControlsScript />}
    </GameObject>
  )
}

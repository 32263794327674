import gen from "random-seed"

import Game from "./@core/Game"
import Scene from "./@core/Scene"
import SceneManager from "./@core/SceneManager"
import Maze from "./scenes/Maze"
import Welcome from "./components/Welcome"
import { Global } from "./styles"
import GameInfo from "./components/GameInfo"

// TODO
// haz un test que verifique que el exit path sea successful hasta dentro de un par de años
// hay menos posiciones en total que hacer test a seed a futuro? (puede haber colisiones) - es decir, haz un exit path para todos los x,y

export const SIZE = 10
export const CENTER = SIZE / 2

function getSeed() {
  const now = new Date()
  const nowStriped = new Date(
    now.getUTCFullYear(),
    now.getUTCMonth(),
    now.getUTCDate(),
  )
  //console.log({ nowStriped })
  const seed = nowStriped.getTime() * 0x100000000
  return `${seed}`
}

function getRnd() {
  const seed = getSeed()
  //console.log({ seed })
  const rnd = gen.create(seed)
  //console.log({ rnd })
  return rnd
}

function getExit(size, rnd) {
  const side = rnd(100) % 4
  //console.log({ side })
  const index = rnd(size - 2) + 1
  //console.log({ index })
  const x = side === 0 ? 0 : side === 1 || side === 3 ? index : size - 1
  const y = side === 0 || side === 2 ? index : side === 1 ? size - 1 : 0

  //console.log({ x, y })
  // this value is normalized to mapData array with 0,0 at top,left
  return { x, y }
}

const MAX = 300

function generateExitPath(size, rnd, exit) {
  const path = []
  const c = size / 2
  let currentX = c
  let currentY = c
  let newX = c
  let newY = c
  // exit is already normalized to mapData array with 0,0 at top,left
  // this is bringing it back to a threeJS coordinate system with 0,0 at bottom,left
  const exitX = exit.y
  const exitY = size - 1 - exit.x

  let b = 0
  let duplicate = null
  while (!(newX === exit.x && newY === exit.y) && b < MAX) {
    b++
    let move = duplicate !== null ? duplicate : rnd(4)

    // if exist near, go towards it
    // will pre-compute a bunch of stuff that gets computed later, but that's ok
    const mapDataX = size - 1 - currentY
    const mapDataY = currentX
    const absMapDataX_exitX = Math.abs(mapDataX - exit.x)
    const absMapDataY_exitY = Math.abs(mapDataY - exit.y)
    //console.log(b, { absMapDataX_exitX, absMapDataY_exitY })
    if ((absMapDataX_exitX <= 3 && absMapDataY_exitY <= 3) || b > 15) {
      duplicate = null
      // console.log("=> force exit")
      // console.log("   absMapDataX_exitX:", absMapDataX_exitX)
      // console.log("   currentX ", currentX)
      // console.log("   mapDataX ", mapDataX)
      // console.log("   absMapDataY_exitY", absMapDataY_exitY)
      // console.log("   currentY ", currentY)
      // console.log("   mapDataY ", mapDataY)
      if (absMapDataX_exitX === 1 && absMapDataY_exitY === 1) {
        // uno  de estos es borde, hay que mover el otro
        if (exit.x === 0 || exit.x === size - 1) {
          if (mapDataY > exit.y) {
            move = 3
          } else {
            move = 1
          }
        } else {
          if (mapDataX > exit.x) {
            move = 0
          } else {
            move = 2
          }
        }
      } else {
        // no estan en abs === 1
        // hay alguno en zero?
        if (absMapDataX_exitX === 0) {
          if (mapDataY > exit.y) {
            move = 3
          } else {
            move = 1
          }
        } else if (absMapDataY_exitY === 0) {
          if (mapDataX > exit.x) {
            move = 0
          } else {
            move = 2
          }
        } else {
          // priorizar x si es que la salida no está en el borde
          if (absMapDataX_exitX > absMapDataY_exitY) {
            if (exitX > 0) {
              if (mapDataX > exit.x) {
                move = 0
              } else {
                move = 2
              }
            } else if (exitX > 0 && absMapDataY_exitY !== 0) {
              if (mapDataY > exit.y) {
                move = 3
              } else {
                move = 1
              }
            }
          } else {
            if (exitX > 0 && absMapDataY_exitY !== 0) {
              if (mapDataY > exit.y) {
                move = 3
              } else {
                move = 1
              }
            } else if (exitY > 0) {
              if (mapDataX > exit.x) {
                move = 0
              } else {
                move = 2
              }
            }
          }
        }
      }
      //console.log("   move: ", move)
    }

    // HACK
    // this makes it so that the move happens twice
    if (duplicate !== null) {
      duplicate = null
    } else {
      duplicate = move
    }
    // 0: y-1, 1: x+1, 2: y+1, 3: x-1
    // 0: up, 1: right, 2: down, 3: left
    const nextX =
      move === 1 ? currentX + 1 : move === 3 ? currentX - 1 : currentX
    const nextY =
      move === 0 ? currentY + 1 : move === 2 ? currentY - 1 : currentY
    // this is normalizing newX and newY to mapData array with 0,0 at top,left
    newX = size - 1 - nextY
    newY = nextX
    //console.log({ move, currentX, currentY, nextX, nextY, newX, newY })
    if (
      ([0, size - 1].includes(nextX) || [0, size - 1].includes(nextY)) &&
      newX !== exit.x &&
      newY !== exit.y
    ) {
      duplicate = null
      continue
    }

    currentX = nextX
    currentY = nextY
    path.push({ x: newX, y: newY })
    if (newX === exit.x && newY === exit.y) {
      console.log("Exit found")
    }
    //console.log({ b })
    if (b >= MAX) {
      console.log("Exit not found")
    }
  }
  // console.log("out: b: ", b)
  // console.log("out: path: ", path)
  // console.log("out: exit: ", exit)

  return path
}

function setRandomWalls(map, size, center, rnd, exitPath) {
  for (let row = 1; row < size - 1; row++) {
    for (let column = 1; column < size - 1; column++) {
      const x = size - 1 - column
      const y = row

      if (row === center && column === center) {
        continue
      }
      const inExitPath = !!exitPath.find((p) => p.y === y && p.x === x)
      if (inExitPath) {
        continue
      }
      const isWall = rnd(3) === 1
      if (!isWall) {
        continue
      }
      map[x][y] = "#"
    }
  }
}

function getMapData(size, center, exit, exitPath, rnd) {
  console.log(exitPath)
  const emptyContent = Array(size - 2).fill("·")
  const emptyLine = ["#", ...emptyContent, "#"]
  const horizontalBorder = Array(size).fill("#")
  const content = Array(size - 2)
    .fill(null)
    .map((_) => [...emptyLine])
  const map = [[...horizontalBorder], ...content, [...horizontalBorder]]
  // exit
  map[exit.x][exit.y] = "e"
  // random walls
  setRandomWalls(map, size, center, rnd, exitPath)

  // debug
  // set walls in exit path
  // exitPath.forEach(({ x, y }) => {
  // map[x][y] = "#"
  // })

  return map
}

function getMaze(size, center) {
  const rnd = getRnd()
  const exit = getExit(size, rnd)
  const exitPath = generateExitPath(size, rnd, exit)
  const map = getMapData(size, center, exit, exitPath, rnd)

  return map
}

function useApp() {
  const maze = getMaze(SIZE, CENTER)

  return { center: CENTER, maze }
}

function App() {
  const { maze, center } = useApp()

  return (
    <>
      <Global />
      <Welcome>
        <GameInfo />
        <Game>
          <SceneManager defaultScene="maze">
            <Scene id="maze">
              <Maze center={center} maze={maze} />
            </Scene>
          </SceneManager>
        </Game>
      </Welcome>
    </>
  )
}

export default App

import React, { useMemo, useState } from "react"
import useGame from "./useGame"
import waitForMs from "./utils/waitForMs"

export const SceneManagerContext = React.createContext(null)

export default function SceneManager({ defaultScene, children }) {
  const { publish } = useGame()
  const [currentScene, setScene] = useState(defaultScene)

  const api = useMemo(
    () => ({
      currentScene,
      async setScene(nextScene) {
        // eslint-disable-next-line prefer-const
        let targetScene = nextScene

        if (currentScene !== targetScene) {
          // switch scene
          if (currentScene !== "") {
            await publish("scene-pre-exit", currentScene)
            await publish("scene-exit", currentScene)
            // always go to empty scene first and then to the target scene
            // (this should help clearing cached react components)
            setScene("")
            await waitForMs(100)
          }
          setScene(targetScene)
        }
      },
      async resetScene() {
        const prevScene = currentScene
        // switch to empty scene
        await api.setScene("")
        await waitForMs(100)
        setScene(prevScene)
      },
    }),
    [currentScene, publish],
  )

  return (
    <SceneManagerContext.Provider value={api}>
      {children}
    </SceneManagerContext.Provider>
  )
}

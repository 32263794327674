import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import useForceUpdate from "./useForceUpdate"
import useGame from "./useGame"
import useStateFromProp from "./useStateFromProp"
import createPubSub from "./utils/createPubSub"

export const GameObjectContext = React.createContext(null)

export default function GameObject({
  name,
  displayName,
  layer,
  children,
  disabled: initialDisabled = false,
  ...props
}) {
  const identifier = useRef(Symbol("GameObject"))
  const node = useRef(null)
  const [registry] = useState(() => new Map())
  const [pubSub] = useState(() => createPubSub())
  const [x, setX] = useStateFromProp(props.x || 0)
  const [y, setY] = useStateFromProp(props.y || 0)
  const [z, setZ] = useStateFromProp(props.z || 0)
  const [disabled, setDisabled] = useState(initialDisabled)
  const { registerGameObject, unregisterGameObject } = useGame()
  const forceUpdate = useForceUpdate()

  const registryUtils = useMemo(
    () => ({
      registerComponent(id, api) {
        registry.set(id, api)
      },
      unregisterComponent(id) {
        registry.delete(id)
      },
      getComponent(id) {
        return registry.get(id)
      },
    }),
    [registry],
  )

  const transform = useMemo(
    () => ({
      x,
      y,
      z,
      setX,
      setY,
      setZ,
    }),
    [x, y, z, setX, setY, setZ],
  )

  const gameObjectRef = useMemo(
    () => ({
      id: identifier.current,
      name,
      displayName,
      layer,
      transform,
      getComponent: registryUtils.getComponent,
      disabled,
      setDisabled,
      subscribe: pubSub.subscribe,
    }),
    [name, displayName, layer, transform, registryUtils, disabled, pubSub],
  )

  const getRef = useCallback(() => gameObjectRef, [gameObjectRef])

  useLayoutEffect(() => {
    const id = identifier.current
    registerGameObject(id, gameObjectRef)
    return () => unregisterGameObject(id, gameObjectRef)
  }, [registerGameObject, unregisterGameObject, gameObjectRef])

  const contextValue = {
    id: identifier.current,
    name,
    transform,
    forceUpdate,
    nodeRef: node,
    getRef,
    ...pubSub,
    ...registryUtils,
  }

  return (
    <GameObjectContext.Provider value={contextValue}>
      <group ref={node} position={[x, y, z]}>
        {!disabled && children}
      </group>
    </GameObjectContext.Provider>
  )
}

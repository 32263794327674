import { useState } from "react"
import styled from "styled-components"

import ModalLayer from "../ui/ModalLayer"

function GameInfo({ children }) {
  const [active, setActive] = useState(true)

  return (
    <>
      <ModalLayer show={active}>
        <Overlay>
          <Wrap>
            <H2>Welcome!</H2>
            <Text>Every day a different maze.</Text>
            <Text>Let's see how many moves it takes you to find the exit!</Text>
            <Btn
              onClick={() => {
                setActive(false)
              }}
            >
              Start
            </Btn>
          </Wrap>
        </Overlay>
      </ModalLayer>
      {!active && children}
    </>
  )
}

const Btn = styled.button`
  background: #8080ff;
  border: none;
  padding: 12px;
  margin: 4px;
  border-radius: 4px;
  font-size: 24px;
  font-family: sans-serif;
  color: white;
  cursor: pointer;

  &:hover,
  &:active {
    background: #8080ffaa;
  }
`

const H2 = styled.h2`
  margin: 0;
`

const Text = styled.div`
  font-size: 18px;
`

const Wrap = styled.div`
  border: 2px solid white;
  border-radius: 8px;
  padding: 20px;
  background: #030111;
  display: grid;
  grid-gap: 24px;
`

const Overlay = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #03011122;
  color: white;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
`

export default GameInfo

import { useState } from "react"
import { Html } from "@react-three/drei"
import styled from "styled-components"

import Collider from "../@core/Collider"
import GameObject from "../@core/GameObject"
import useGame from "../@core/useGame"
import useGameObjectEvent from "../@core/useGameObjectEvent"
import ModalLayer from "../ui/ModalLayer"
import useStore from "../store"

import { SIZE } from "../App"

function rotateClockwise(matrix) {
  return matrix[0].map((column, index) => matrix.map((row) => row[index]))
}

function emojifyPath(exitPath) {
  if (!exitPath.length) {
    return ""
  }
  const exitPathXY = exitPath.map(({ x, y }) => ({ x, y: SIZE - 1 - y }))
  const withExitPath = Array(SIZE)
    .fill(" ")
    .map((_, column) => {
      return Array(SIZE)
        .fill(" ")
        .map((_, row) => {
          const exists = !!exitPathXY.find(({ x, y }) => {
            return row === x && y === column
          })
          return exists ? "." : " "
        })
    })
  const filteredRows = withExitPath.filter((l) => l.includes("."))
  const clockwise = rotateClockwise(filteredRows)
  const filteredColumns = clockwise.filter((l) => l.includes("."))
  const trimmed = rotateClockwise(
    rotateClockwise(rotateClockwise(filteredColumns)),
  )

  return trimmed
    .map((l) => l.join(""))
    .join("\n")
    .replaceAll(".", "🟦")
    .replaceAll(" ", "⬛")
}

function ExitScript() {
  const [active, setActive] = useState(false)
  const { setPaused } = useGame()
  const { steps, exitPath } = useStore()

  useGameObjectEvent("trigger", (other) => {
    if (other.name === "player") {
      setPaused(true)
      setActive(true)
    }
  })

  const emojifiedPath = emojifyPath(exitPath)
  const tweet = `I got out in ${steps} moves:\n${emojifiedPath}\nhttps://maze.aquigorka.com`
  const twitterIntentURL = `https://x.com/intent/tweet?text=${encodeURIComponent(tweet)}`

  return (
    <Html>
      <ModalLayer show={active} z={2}>
        <Overlay>
          <Wrap>
            <H2>Congratulations!</H2>
            <Text>
              You got out in {steps} moves.
              <TextBtn onClick={() => window.location.reload()}>
                Start over
              </TextBtn>
            </Text>
            <Centered>
              {emojifiedPath.split("\n").map((l, index) => (
                <Pre key={`${l}-${index}`}>{l}</Pre>
              ))}
            </Centered>
            <Btn
              onClick={() => {
                window.open(twitterIntentURL, "_top")
              }}
            >
              Share on X
            </Btn>
          </Wrap>
        </Overlay>
      </ModalLayer>
    </Html>
  )
}

function Exit(props) {
  return (
    <GameObject {...props}>
      <Collider isTrigger />
      <ExitScript />
    </GameObject>
  )
}

const TextBtn = styled.button`
  background: none;
  border: none;
  color: white;
  width: 80px;
  font-size: 18px;
  font-family: open-sans;
  padding: 0;
  border-bottom: 1px solid gray;
  cursor: pointer;
`

const Centered = styled.div`
  margin: auto;
`

const Pre = styled.pre`
  margin: 0;
`

const Btn = styled.button`
  background: #8080ff;
  border: none;
  padding: 12px;
  margin: 4px;
  border-radius: 4px;
  font-size: 24px;
  cursor: pointer;
  color: white;

  &:hover,
  &:active {
    background: #8080ffaa;
  }
`

const H2 = styled.h2`
  margin: 0;
`

const Text = styled.div`
  font-size: 18px;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 8px;
`

const Wrap = styled.div`
  border: 2px solid white;
  border-radius: 8px;
  padding: 20px;
  background: #030111;
  display: grid;
  grid-gap: 24px;
`

const Overlay = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #030111cc;
  color: white;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
`
export default Exit

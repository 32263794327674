import { useState, useRef } from "react"
import useComponentRegistry from "./useComponentRegistry"

export default function Sprite({
  sheet,
  state: initialState = "default",
  flipX: initialFlipX,
  color: initialColor,
  opacity: initialOpacity,
  offset: initialOffset,
  scale: initialScale,
  meshInstance = null,
  ...graphicProps
}) {
  const [, setColor] = useState(initialColor)
  const [, setOpacity] = useState(initialOpacity)
  const [flipX, setFlipX] = useState(initialFlipX)
  const [, setState] = useState(initialState)
  const [, setOffset] = useState(initialOffset)
  const [, setScale] = useState(initialScale)
  const nodeRef = useRef()

  useComponentRegistry("Sprite", {
    setColor,
    setOpacity,
    setState,
    setOffset,
    setScale,
    setFlipX,
    flipX,
    nodeRef,
  })

  return meshInstance
}

import React, { useEffect, useMemo } from "react"
import useGame from "./useGame"

export default function TileMap({ data, resolver, definesMapSize = false }) {
  const { setMapSize, publish } = useGame()
  // const [mapData, setMapData] = useState([]);
  const mapData = useMemo(() => data.slice().reverse(), [data])

  useEffect(() => {
    publish("tile-map-update")
  }, [mapData, publish])

  useEffect(() => {
    if (definesMapSize && mapData.length) {
      const sizeX = mapData[0].length
      const sizeY = mapData.length
      setMapSize([sizeX, sizeY])
    }
  }, [mapData, definesMapSize, setMapSize])

  if (!resolver) return null

  return (
    <>{mapData.map((row, y) => row.map((type, x) => resolver(type, x, y)))}</>
  )
}

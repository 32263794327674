import styled from "styled-components"

import ModalLayer from "../ui/ModalLayer"
import useStore from "../store"

function GameInfo({ children }) {
  const { steps } = useStore()

  return (
    <ModalLayer show={true}>
      <Wrap>
        <H2>{steps}</H2>
      </Wrap>
    </ModalLayer>
  )
}

const H2 = styled.h2`
  margin: 0;
`

const Wrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding: 20px;
  grid-gap: 24px;
  color: white;
`

export default GameInfo

import React, { Fragment } from "react"
import * as THREE from "three"

import Collider from "../@core/Collider"
import GameObject from "../@core/GameObject"
import Sprite from "../@core/Sprite"
import TileMap from "../@core/TileMap"
import Player from "../entities/Player"
import Exit from "../entities/Exit"
import Fog from "../entities/Fog"

const planeGeometry = new THREE.PlaneGeometry(1, 1)
const boxGeometry = new THREE.BoxGeometry(1, 1, 1)

const f = (
  <mesh geometry={planeGeometry}>
    <meshNormalMaterial color="black" />
  </mesh>
)

function Wall() {
  return (
    <mesh geometry={boxGeometry}>
      <meshNormalMaterial color="gray" />
    </mesh>
  )
}

const resolveMapTile = (center) => (type, x, y) => {
  const key = `${x}-${y}`
  const position = { x, y }

  const floor = (
    <GameObject key={key} {...position} z={-0.5}>
      <Sprite meshInstance={f} />
    </GameObject>
  )

  switch (type) {
    case "·":
      return (
        <Fragment key={key}>
          {floor}
          {!(position.x === center && position.y === center) && (
            <Fog {...position} />
          )}
        </Fragment>
      )
    case "#":
      return (
        <GameObject key={key} {...position}>
          <Collider />
          <Sprite meshInstance={<Wall />} />
          <Fog x={0} y={0} />
        </GameObject>
      )
    case "e":
      return (
        <Fragment key={key}>
          {floor}
          <Exit {...position} />
          <Fog {...position} />
        </Fragment>
      )
    default:
      console.log("Missing tile resolver")
      return null
  }
}

export default function Maze({ maze, center }) {
  return (
    <>
      <GameObject name="map">
        <TileMap
          data={maze}
          resolver={resolveMapTile(center)}
          definesMapSize
        />
      </GameObject>
      <Player x={center} y={center} />
    </>
  )
}

import { createGlobalStyle } from "styled-components"

export const Global = createGlobalStyle`

  * {
    box-sizing: border-box;
    user-select: none;
  }

  html,
  body,
  #root {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    user-select: none;
    overflow: hidden;
    font-family: sans-serif;
  }

  #root {
    overflow: hidden;
    background: #030111;
  }
`
